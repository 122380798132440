import { createRoot } from 'react-dom/client';
import { lazy, Suspense } from 'react';
import './App.scss';
import './i18n';
const App = lazy(() => import('./App'));

createRoot(document.getElementById('root')!).render(
  <Suspense>
    <App />
  </Suspense>
);
